








































































































































import { debounceInput } from '@/shared/helpers'
import { IUser, User } from '@/shared/model/user.model'
import { IVehicle, Vehicle } from '@/shared/model/vehicle.model'
import { ICompanyVehicle, CompanyVehicle } from '@/shared/model/companyVehicle.model'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import vehicleService from '@/shared/services/vehicleService'

export default Vue.extend({
  name: 'CompanyVehicleWizard',

  props: {
    selectedCompanyVehicle: {
      type: Object,
      default: () => undefined,
    },
  },
  data () {
    return {
      options: {} as any,
      optionsManufacturers: {} as any,

      dialog: false,

      vehicleManufacturerDebounce: undefined as number | undefined,
      vehicleDebounce: undefined as number | undefined,

      selectedVehicle: undefined as IVehicle | undefined,
      selectedVehicleManufacturer: undefined as any | undefined,
      vehicleAutocomplete: '',
      vehicleManufacturerAutocomplete: '',

      filteredVehicles: [] as Vehicle[],

      companyVehicle: new CompanyVehicle(),

      tab: 0,
      tabs: [this.$t('vehicle').toString(), this.$t('details').toString()],
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      vehicles: 'vehicleStore/getList',
      vehicleManufacturers: 'vehicleManufacturerStore/getList',
      updateVehicleError: 'companyVehicleStore/getError',
    }),
    availableSteps () {
      const steps = [0]

      if (this.selectedVehicleManufacturer && this.companyVehicle.constructionYear && this.selectedCompanyVehicle) {
        steps.push(1)
      }

      if (
        this.companyVehicle.owner &&
        this.companyVehicle.vin &&
        this.companyVehicle.licencePlate &&
        steps.includes(1)
      ) {
        steps.push(2)
      }

      return steps
    },
  },
  watch: {
    vehicleManufacturerAutocomplete: {
      async handler () {
        this.vehicleManufacturerDebounce = debounceInput(
          this.vehicleManufacturerDebounce,
          this.getAllVehicleManufacturersWithVDataTable,
        )
      },
    },
    vehicleAutocomplete: {
      async handler () {
        this.vehicleDebounce = debounceInput(this.vehicleDebounce, this.getAllVehiclesWithVDataTable)
      },
    },
    selectedVehicleManufacturer: {
      async handler () {
        await this.getAllVehiclesWithVDataTable()
        if (this.selectedVehicle && this.selectedVehicle.manufacturer !== this.selectedVehicleManufacturer._id) {
          this.selectedVehicle = undefined
        }
      },
    },
    selectedVehicle: {
      async handler (value) {
        if (!this.selectedVehicleManufacturer || !this.selectedVehicleManufacturer._id) {
          const vehicle = this.filteredVehicles.find((x) => x.name === value.name)

          if (vehicle && vehicle.manufacturer) {
            this.selectedVehicleManufacturer = this.vehicleManufacturers.find(
              (y: any) => y._id === vehicle.manufacturer,
            )
          }
        }
      },
    },
    vehicles: {
      async handler () {
        this.filterVehiclesByManufacturer()
      },
    },
  },
  created () {
    if (this.selectedCompanyVehicle && this.selectedCompanyVehicle._id) {
      this.selectedVehicle = this.selectedCompanyVehicle.vehicle
      this.selectedVehicleManufacturer = this.selectedCompanyVehicle.manufacturer
      this.companyVehicle = this.selectedCompanyVehicle
    }
  },
  methods: {
    ...mapActions('companyVehicleStore', {
      createCompanyVehicle: 'createItem',
      updateCompanyVehicle: 'updateItem',
    }),
    ...mapActions('vehicleManufacturerStore', {
      createVehicleManufacturer: 'createItem',
    }),
    ...mapActions('vehicleStore', {
      createVehicle: 'createItem',
    }),
    async next (valid: any) {
      if (this.tab === this.tabs.length - 1) {
        await this.saveCompanyVehicle()
        this.wizardFinished()
      } else {
        this.tab++
      }
    },
    async getAllVehicleManufacturersWithVDataTable () {
      const query = {
        l: 20,
        lo: 1,
        sf: 'name',
        so: 'asc',
        search: { search: this.vehicleManufacturerAutocomplete },
      }
      this.$store.dispatch('vehicleManufacturerStore/getList', query)
    },
    async getAllVehiclesWithVDataTable () {
      const query = {
        l: 20,
        lo: 1,
        sf: 'name',
        so: 'asc',
        search: { search: this.vehicleAutocomplete },
      }
      if (this.selectedVehicleManufacturer && this.selectedVehicleManufacturer._id) {
        vehicleService.getVehiclesByManufacturer(this.$store, query, this.selectedVehicleManufacturer._id)
      } else {
        this.$store.dispatch('vehicleStore/getList', query)
      }
      this.filterVehiclesByManufacturer()
    },
    filterVehiclesByManufacturer () {
      if (this.selectedVehicleManufacturer && this.selectedVehicleManufacturer._id) {
        this.filteredVehicles = this.vehicles.filter(
          (x: Vehicle) => x.manufacturer === this.selectedVehicleManufacturer._id,
        )
      } else {
        this.filteredVehicles = this.vehicles
      }
    },
    async saveCompanyVehicle () {
      if (this.selectedVehicleManufacturer && this.selectedVehicleManufacturer._id) {
        this.companyVehicle.manufacturer = this.selectedVehicleManufacturer._id
      } else if (this.vehicleManufacturerAutocomplete && this.vehicleManufacturerAutocomplete.length) {
        this.companyVehicle.manufacturer = await this.createNewVehicleManufacturerByUser(
          this.vehicleManufacturerAutocomplete,
        )
      }

      if (this.selectedVehicle && this.selectedVehicle._id) {
        this.companyVehicle.vehicle = this.selectedVehicle._id
      } else if (
        this.vehicleAutocomplete &&
        this.vehicleAutocomplete.length &&
        this.companyVehicle.manufacturer &&
        this.companyVehicle.manufacturer.length
      ) {
        this.companyVehicle.vehicle = await this.createNewVehicleByUser(
          this.vehicleAutocomplete,
          this.companyVehicle.manufacturer,
        )
      }

      if (this.companyVehicle.manufacturer && this.companyVehicle.vehicle) {
        if (this.companyVehicle._id) {
          await this.updateCompanyVehicle({
            id: this.companyVehicle._id,
            item: this.companyVehicle,
          })
        } else {
          await this.createCompanyVehicle({ item: this.companyVehicle })
        }
      }
    },
    wizardFinished () {
      if (this.updateVehicleError) {
        if (this.updateVehicleError.response && this.updateVehicleError.response.status === 400) {
          this.$toast.error(this.$t('dialog.vehicle-already-exists').toString())
        } else {
          this.$toast.error(this.$t('dialog.an-unexpected-error-occured').toString())
        }
      } else {
        this.$toast(this.$t('dialog.vehicle-save-successfully').toString())
        this.$emit('wizardfinished')
      }
    },
    autocompleteFilter (item: any, queryText: string, itemText: string) {
      return item.name.toLowerCase().includes(queryText.toLowerCase())
    },
    async createNewVehicleManufacturerByUser (name: string): Promise<string | undefined> {
      const newVehicleManufacturer = await vehicleService.createVehicleManufacturerByUser(this.$store, {
        name: name,
      })

      if (newVehicleManufacturer && newVehicleManufacturer._id) {
        this.$toast(this.$t('dialog.save-vehicle-manufacturer-success').toString())
        return newVehicleManufacturer._id
      } else {
        this.$toast.error(this.$t('dialog.save-vehicle-manufacturer-error').toString())
        return undefined
      }
    },
    async createNewVehicleByUser (name: string, manufacturer: string): Promise<string | undefined> {
      const newVehicle = await vehicleService.createVehicleByUser(this.$store, {
        name: name,
        manufacturer: manufacturer,
      })
      if (newVehicle && newVehicle._id) {
        this.$toast(this.$t('dialog.save-vehicle-success').toString())
        return newVehicle._id
      } else {
        this.$toast.error(this.$t('dialog.save-vehicle-error').toString())
        return undefined
      }
    },
  },
})
