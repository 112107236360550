var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('base-material-wizard-final',{staticClass:"mx-auto",attrs:{"available-steps":_vm.availableSteps,"items":_vm.tabs,"headline":_vm.$t('create-vehicle'),"sub-headline":_vm.$t('dialog.the-most-important-information-about-the-vehicle')},on:{"click:next":function($event){return _vm.next(valid)},"click:prev":function($event){_vm.tab--},"click:abort":function($event){return _vm.$emit('wizardfinished')}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{staticClass:"pb-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('vehicle-manufacturer')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.vehicleManufacturers,"error-messages":errors,"search-input":_vm.vehicleManufacturerAutocomplete,"color":"secondary","label":_vm.$t('vehicle-manufacturer') + '*',"item-text":"name","prepend-icon":"mdi-car-cog","return-object":""},on:{"update:searchInput":function($event){_vm.vehicleManufacturerAutocomplete=$event},"update:search-input":function($event){_vm.vehicleManufacturerAutocomplete=$event}},model:{value:(_vm.selectedVehicleManufacturer),callback:function ($$v) {_vm.selectedVehicleManufacturer=$$v},expression:"selectedVehicleManufacturer"}},[_c('div',{staticStyle:{"padding":"10px"},attrs:{"slot":"no-data"},slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('dialog.this-manufacturer-does-not-exist'))+" ")])])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('vehicle')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-combobox',{attrs:{"items":_vm.filteredVehicles,"error-messages":errors,"search-input":_vm.vehicleAutocomplete,"color":"secondary","label":_vm.$t('vehicle') + '*',"item-text":"name","prepend-icon":"mdi-car","return-object":""},on:{"update:searchInput":function($event){_vm.vehicleAutocomplete=$event},"update:search-input":function($event){_vm.vehicleAutocomplete=$event}},model:{value:(_vm.selectedVehicle),callback:function ($$v) {_vm.selectedVehicle=$$v},expression:"selectedVehicle"}},[_c('div',{staticStyle:{"padding":"10px"},attrs:{"slot":"no-data"},slot:"no-data"},[_vm._v(" "+_vm._s(_vm.$t('dialog.this-vehicle-does-not-exist'))+" ")])])]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"constructionyear","name":_vm.$t('construction-year')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":_vm.$t(errors[0]),"color":"secondary","label":_vm.$t('construction-year') + '*',"prepend-icon":"mdi-car-wrench"},model:{value:(_vm.companyVehicle.constructionYear),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "constructionYear", $$v)},expression:"companyVehicle.constructionYear"}})]}}],null,true)})],1)],1),_c('v-tab-item',{staticClass:"pb-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('fullname')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('owner') + '(' + _vm.$t('fullname') + ')*',"prepend-icon":"mdi-account"},model:{value:(_vm.companyVehicle.owner),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "owner", $$v)},expression:"companyVehicle.owner"}})]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('vin')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('vin') + '*',"prepend-icon":"mdi-car-info"},model:{value:(_vm.companyVehicle.vin),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "vin", $$v)},expression:"companyVehicle.vin"}})]}}],null,true)}),_c('v-text-field',{attrs:{"color":"secondary","label":_vm.$t('sn'),"prepend-icon":"mdi-car-info"},model:{value:(_vm.companyVehicle.sn),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "sn", $$v)},expression:"companyVehicle.sn"}}),_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('licence-plate')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"color":"secondary","label":_vm.$t('licence-plate') + '*',"prepend-icon":"mdi-car-back"},model:{value:(_vm.companyVehicle.licencePlate),callback:function ($$v) {_vm.$set(_vm.companyVehicle, "licencePlate", $$v)},expression:"companyVehicle.licencePlate"}})]}}],null,true)})],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }